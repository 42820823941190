import { video } from "./snippets/media";
import { internalLink } from "./snippets/internalLink";
import { builder } from "./snippets/builder";
import { product, variant } from "./snippets/product";

export const queryPageHome = groq`*[_type == "home"] | order(_updatedAt desc)[0] {
  _id,
  landing{
    ...,
    video{
      ${video}
    },
    button{
      title,
      reference->{
        ${internalLink}
      }
    },
    productLink{
      ${product},
      ${variant}
    }
  },
  ${builder},
  seo
}`;
